.snackbar {
    align-items: center;
    display: flex;
    text-align: center;
}

.errorIcon { 
    margin-right: 20px;
}
.warningIcon {
    margin-right: 20px;
}
.successIcon { 
    margin-right: 20px;
}

.errorPage {
    margin: 20px;
}

img {
    max-width: 100%;
}